import { Card, Col, Row, Table } from "antd";
import { ReportListViewProps } from "./types";
import { v4 as uuidv4 } from "uuid";
import { DonutChart } from "../DonutChart";
import { BarChart } from "../BarChart";

type ComponentType = React.FC<ReportListViewProps>;
export const WeeklyReportView: ComponentType = (props) => {
	const { report } = props;

	const dataSourceDadosAcionamentosTotais: any = [
		{
			key: uuidv4(),
			metrica: "Acionamentos totais",
			bombaIndex1: report.produtcsTotal[0].acionamentos,
			bombaIndex2: report.produtcsTotal[1].acionamentos,
			bombaIndex3: report.produtcsTotal[2].acionamentos,
			bombaIndex4: report.produtcsTotal[3].acionamentos,
			total: report.produtcsTotal.reduce((total, produto) => {
				return total + produto.acionamentos;
			}, 0),
		},
		{
			key: uuidv4(),
			metrica: "Volumes dosados totais",
			bombaIndex1: report.produtcsTotal[0].volumeDosador,
			bombaIndex2: report.produtcsTotal[1].volumeDosador,
			bombaIndex3: report.produtcsTotal[2].volumeDosador,
			bombaIndex4: report.produtcsTotal[3].volumeDosador,
			total: report.produtcsTotal.reduce((total, produto) => {
				return total + produto.volumeDosador;
			}, 0),
		},
	];
	const dataSourceTableAcionamentosPorHorarioPorBomba: any = report.dadosAcionamentosTotais.map((item) => {
		return {
			key: uuidv4(),
			horaInicio: item[0],
			horaFim: item[1],
			bombaIndex1: item[2],
			bombaIndex2: item[3],
			bombaIndex3: item[4],
			bombaIndex4: item[5],
			total: item[6],
		};
	});

	const columnssdfgsdfg = [
		{
			title: "Hora Inicio",
			dataIndex: "horaInicio",
			key: "horaInicio",
		},
		{
			title: "Hora Fim",
			dataIndex: "horaFim",
			key: "horaFim",
		},
		{
			title: "Bomba 1",
			dataIndex: "bombaIndex1",
			key: "bombaIndex1",
		},
		{
			title: "Bomba 2",
			dataIndex: "bombaIndex2",
			key: "bombaIndex2",
		},
		{
			title: "Bomba 3",
			dataIndex: "bombaIndex3",
			key: "bombaIndex3",
		},
		{
			title: "Bomba 4",
			dataIndex: "bombaIndex4",
			key: "bombaIndex4",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
		},
	];

	const columns = [
		{
			title: "Metricas",
			dataIndex: "metrica",
			key: "metrica",
		},
		{
			title: "Bomba 1",
			dataIndex: "bombaIndex1",
			key: "bombaIndex1",
		},
		{
			title: "Bomba 2",
			dataIndex: "bombaIndex2",
			key: "bombaIndex2",
		},
		{
			title: "Bomba 3",
			dataIndex: "bombaIndex3",
			key: "bombaIndex3",
		},
		{
			title: "Bomba 4",
			dataIndex: "bombaIndex4",
			key: "bombaIndex4",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
		},
	];

	const columns2 = [
		{
			title: "Bomba 1",
			dataIndex: "title1",
			key: "title1",
		},
		{
			title: "",
			dataIndex: "value1",
			key: "value1",
		},
		{
			title: "Bomba 2",
			dataIndex: "title2",
			key: "title2",
		},
		{
			title: "",
			dataIndex: "value2",
			key: "value2",
		},
	];

	const dataSource2 = [
		{
			key: uuidv4(),
			title1: "Produto",
			value1: "",
			title2: "Produto",
			value2: "",
		},
		{
			key: uuidv4(),
			title1: "Acionamentos",
			value1: report?.produtcs[0]?.acionamentos ?? 0,
			title2: "Acionamentos",
			value2: report?.produtcs[1]?.acionamentos ?? 0,
		},
		{
			key: uuidv4(),
			title1: "Volume Dosado (ml)",
			value1: report?.produtcs[0]?.volumeDosador ?? 0,
			title2: "Volume Dosado (ml)",
			value2: report?.produtcs[1]?.volumeDosador ?? 0,
		},
		{
			key: uuidv4(),
			title1: "Volume Médio Dosado (ml)",
			value1: report?.produtcs[0]?.volumeDosadorMedio ?? 0,
			title2: "Volume Médio Dosado (ml)",
			value2: report?.produtcs[1]?.volumeDosadorMedio ?? 0,
		},
	];

	const dataSource4 = [
		{
			key: uuidv4(),
			title3: "Produto",
			value3: "",
			title4: "Produto",
			value4: "",
		},
		{
			key: uuidv4(),
			title3: "Acionamentos",
			value3: report?.produtcs[2]?.acionamentos ?? 0,
			title4: "Acionamentos",
			value4: report?.produtcs[3]?.acionamentos ?? 0,
		},
		{
			key: uuidv4(),
			title3: "Volume Dosado (ml)",
			value3: report?.produtcs[2]?.volumeDosador ?? 0,
			title4: "Volume Dosado (ml)",
			value4: report?.produtcs[3]?.volumeDosador ?? 0,
		},
		{
			key: uuidv4(),
			title3: "Volume Médio Dosado (ml)",
			value3: report?.produtcs[2]?.volumeDosadorMedio ?? 0,
			title4: "Volume Médio Dosado (ml)",
			value4: report?.produtcs[3]?.volumeDosadorMedio ?? 0,
		},
	];

	const columns4 = [
		{
			title: "Bomba 3",
			dataIndex: "title3",
			key: "title3",
		},
		{
			title: "",
			dataIndex: "value3",
			key: "value3",
		},
		{
			title: "Bomba 4",
			dataIndex: "title4",
			key: "title4",
		},
		{
			title: "",
			dataIndex: "value4",
			key: "value4",
		},
	];

	let dataSource3 = report?.triggerDaily?.daily.map((item) => {
		return {
			key: uuidv4(),
			dia: item.dia,
			total: item.quantidade,
		};
	});

	dataSource3 = dataSource3.concat({
		key: uuidv4(),
		dia: "",
		total: report?.triggerDaily?.total,
	});

	const columns3 = [
		{
			title: "Dia",
			dataIndex: "dia",
			key: "dia",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
		},
	];

	const rowClassName = (record, index) => {
		return index === dataSource3.length - 1 ? "last-row" : "";
	};

	return (
		<Card
			style={{
				border: "2px solid #35405266",
				marginBottom: "10px",
				marginTop: "10px",
				borderRadius: "10px",
				backgroundColor: "#f5f5f5",
				overflow: "auto",
			}}
			key={uuidv4()}
		>
			<div style={{ paddingBottom: 16 }}>
				<Row justify="space-between" align="top">
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							PERÍODO:
						</Card>
					</Col>
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							{report.periodo}
						</Card>
					</Col>
				</Row>
				<Row justify="space-between" align="top">
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							EQUIPAMENTO:
						</Card>
					</Col>
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							{report.deviceName ?? "-"}
						</Card>
					</Col>
				</Row>
				<Row justify="space-between" align="top">
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							ID:
						</Card>
					</Col>
					<Col span={12}>
						<Card className="myCardPadding5" style={{ textAlign: "left" }}>
							{report.serialCode}
						</Card>
					</Col>
				</Row>
			</div>

			<Row gutter={16}>
				<Col span={12}>
					<Table
						className="myTable"
						pagination={false}
						dataSource={dataSource2}
						columns={columns2}
					/>
					<Table
						className="myTable"
						pagination={false}
						dataSource={dataSource4}
						columns={columns4}
						style={{ marginTop: "16px" }}
					/>
				</Col>
				<Col span={12}>
					<Card
						className="myCard myCardWithoutPadding"
						title={
							<div style={{ textAlign: "center" }}>Totais de Acionamento por Dia</div>
						}
					>
						<Table
							className="myTable"
							pagination={false}
							dataSource={dataSource3}
							columns={columns3}
							rowClassName={rowClassName}
						/>
					</Card>
				</Col>
			</Row>

			<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
				<Card
					title="Acionamentos Totais"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<DonutChart
						data={{
							title: "Acionamentos Totais",
							labels: report.tableAcionamentoTotais.map((item) => {
								return item.legenda;
							}),
							series: report.tableAcionamentoTotais.map((item) => {
								return item.value;
							}),
						}}
					></DonutChart>
				</Card>
				<Card
					title="Acionamentos por Dia"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<DonutChart
						data={{
							title: "Acionamentos por Dia",
							labels: report.acionamentosPorDiaPorcetagem.map((item) => {
								return item.legenda;
							}),
							series: report.acionamentosPorDiaPorcetagem.map((item) => {
								return item.quantidade;
							}),
						}}
					></DonutChart>
				</Card>
				<Card
					title="Acionamentos por Dia"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<BarChart
						data={{
							series:
								report?.acionamentosPorDiaPorcetagem?.map((item) => {
									return item.quantidade;
								}) ?? [],
							labels:
								report?.acionamentosPorDiaPorcetagem?.map((item) => {
									return item.legenda;
								}) ?? [],
							title: "Acionamentos por Dia",
						}}
					/>
				</Card>
			</div>

			<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
				<Card
					title="Totais Dosados"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<DonutChart
						data={{
							title: "Totais Dosados",
							labels: report.tableTotaisDosados.map((item) => {
								return item.legenda;
							}),
							series: report.tableTotaisDosados.map((item) => {
								return item.value;
							}),
						}}
					></DonutChart>
				</Card>
				<Card
					title="Acionamento por Horário"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<DonutChart
						data={{
							title: "Acionamento por Horário",
							labels: report.acionamentosPorHorario.map((item) => {
								return item.legenda;
							}),
							series: report.acionamentosPorHorario.map((item) => {
								return item.value;
							}),
						}}
					></DonutChart>
				</Card>
				<Card
					title="Acionamentos por Horário"
					style={{ flex: 1, marginRight: 16, marginTop: 16, minWidth: 450 }}
				>
					<BarChart
						data={{
							series:
								report?.acionamentosPorHorario?.map((item) => {
									return item.value;
								}) ?? [],
							labels:
								report?.acionamentosPorHorario?.map((item) => {
									return item.intervalo;
								}) ?? [],
							title: "Acionamentos por Horário",
						}}
					/>
				</Card>
			</div>

			<div style={{ marginTop: 16 }}>
				<Card
					className="myCard myCardWithoutPadding"
					title={<div style={{ textAlign: "center" }}>Acionamento por Horário</div>}
				>
					<Table
						className="myTable"
						pagination={false}
						dataSource={dataSourceTableAcionamentosPorHorarioPorBomba}
						columns={columnssdfgsdfg}
					/>
				</Card>
			</div>

			<div style={{ marginTop: 16 }}>
				<Card
					className="myCard myCardWithoutPadding"
					title={<div style={{ textAlign: "center" }}>Acionamento Total por Horário</div>}
				>
					<Table
						className="myTable"
						pagination={false}
						dataSource={dataSourceDadosAcionamentosTotais}
						columns={columns}
					/>
				</Card>
			</div>
		</Card>
	);
};
