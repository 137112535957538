import { useHttp } from "@/application/HttpClient";
import { Form } from "antd";
import { useCallback, useMemo, useState } from "react";
import { DeviceDataFormData, DeviceDataFormProps } from "./types";
import { DeviceDataFormView } from "./view";
import { deviceDataService } from "@/services/device-data";

type ComponentType = React.FC<DeviceDataFormProps>;
export const DeviceDataForm: ComponentType = (props) => {
	const { request } = useHttp();
	const { onSubmitting, onSubmitted, onSubmissionFailed } = props;

	const [form] = Form.useForm<DeviceDataFormData>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const initialValue = useMemo(
		() =>
			props.deviceData
				? { ...props.deviceData, deviceId: props.deviceId }
				: { deviceId: props.deviceId },
		[props.deviceData, props.deviceId]
	);

	const onSubmit = useCallback(() => {
		if (submitting) return;
		debugger;
		const formData = form.getFieldsValue();

		const updatedValues = {
			...formData,
			sundayOptionUpdate: formData.sundayOptionUpdate ? "on" : "",
			mondayOptionUpdate: formData.mondayOptionUpdate ? "on" : "",
			tuesdayOptionUpdate: formData.tuesdayOptionUpdate ? "on" : "",
			wednesdayOptionUpdate: formData.wednesdayOptionUpdate ? "on" : "",
			thursdayOptionUpdate: formData.thursdayOptionUpdate ? "on" : "",
			fridayOptionUpdate: formData.fridayOptionUpdate ? "on" : "",
			saturdayOptionUpdate: formData.saturdayOptionUpdate ? "on" : "",
			updateAlways: formData.updateAlways ? "on" : "",
		  };

		const doRequest = () =>
			props.deviceData
				? request(deviceDataService.edit, {
						deviceDataId: props.deviceData?.id,
						...updatedValues,
				  })
				: request(deviceDataService.create, updatedValues);

		onSubmitting?.();
		setSubmitting(true);

		doRequest()
			.then((deviceData) => onSubmitted?.(deviceData))
			.catch((error) => onSubmissionFailed?.(error))
			.finally(() => setSubmitting(false));
	}, [
		form,
		onSubmissionFailed,
		onSubmitted,
		onSubmitting,
		props.deviceData,
		request,
		submitting,
	]);

	return (
		<DeviceDataFormView
			form={form}
			submitting={submitting}
			isEditMode={!!props.deviceData}
			initialValue={initialValue}
			onSubmit={onSubmit}
		/>
	);
};
