import { Device } from "@/models/Device";
import { DeviceStatus } from "@/models/DeviceStatus";
import { formatOptional } from "@/utilities/format/optional";
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	BarsOutlined,
	InfoCircleOutlined,
	MinusCircleOutlined,
	SearchOutlined,
	SwitcherOutlined,
	SettingOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import {
	Button,
	Input,
	Pagination,
	Popconfirm,
	Row,
	Select,
	Space,
	Table,
	Tag,
	notification,
} from "antd";
import { DeviceListViewProps, DeviceStatusTagDefinition } from "./types";
import { useMemo, useState } from "react";
import { useAuthorizationContext } from "@/contexts/Authorization";
import { deviceDataService } from "@/services/device-data";
import { useHttp } from "@/application/HttpClient";
const { Option } = Select;

const tags: DeviceStatusTagDefinition = {
	[DeviceStatus.Idle]: (
		<Tag color="warning" icon={<MinusCircleOutlined />}>
			Sem comunicação
		</Tag>
	),
	[DeviceStatus.Available]: (
		<Tag color="default" icon={<InfoCircleOutlined />}>
			Pronto para associar
		</Tag>
	),
	[DeviceStatus.NotConfigured]: (
		<Tag color="warning" icon={<InfoCircleOutlined />}>
			Pronto para configurar
		</Tag>
	),
	[DeviceStatus.Operational]: (
		<Tag color="success" icon={<CheckCircleOutlined />}>
			Em funcionamento
		</Tag>
	),
	[DeviceStatus.Error]: (
		<Tag color="error" icon={<CloseCircleOutlined />}>
			Erro
		</Tag>
	),
};

type ComponentType = React.FC<DeviceListViewProps>;
export const DeviceListView: ComponentType = (props) => {
	const { total } = props;

	const authorization = useAuthorizationContext();

	const allowCreate = useMemo(() => !authorization.canCreate("Device"), [authorization]);

	const [sortOrder, setSortOrder] = useState<any | null>(null);

	const [searchText, setSearchText] = useState("");

	const handlePageChange = (page: number) => {
		props.onPagination(page);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys[0]);
	};

	const handleSearchEnum = (selectedKeys, confirm, dataIndex) => {
		confirm();
		props.onFilter(dataIndex, selectedKeys);
	};

	const renderLaundryUnit = (device: any) => {
		return device?.laundryUnit?.name ?? "-";
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		props.onFilter("", "");
	};

	const handleSort = (field: string, order: "ascend" | "descend") => {
		if (sortOrder?.field === field && sortOrder?.order === order) {
			setSortOrder({ field, order: order === "ascend" ? "descend" : "ascend" });
		} else {
			setSortOrder({ field, order });
		}

		props.onSort(field, order === "descend" ? "desc" : "asc");
	};

	const { request } = useHttp();

	const onDownloadFileConfig = (url: any, serialCode: string) => {
		request(url, { serialCode })
			.then((res) => {})
			.catch((err: any) => {
				console.log(err);
				notification.error({ message: "Erro ao baixar o arquivo de configuracao" });
			})
			.finally(() => {});
	};

	return (
		<>
			<Table
				rowKey="id"
				title={() => "Equipamentos"}
				dataSource={props.devices}
				loading={props.loading}
				style={{ width: "100%" }}
				pagination={false}
			>
				<Table.Column
					showSorterTooltip={false}
					dataIndex="serialCode"
					title="Código"
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("serialCode", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Input
								placeholder={`Buscar código`}
								value={selectedKeys[0]?.toString()}
								onChange={(e) =>
									setSelectedKeys(e.target.value ? [e.target.value] : [])
								}
								onPressEnter={() =>
									handleSearch(selectedKeys, confirm, "serialCode")
								}
								style={{ width: 188, marginBottom: 8, display: "block" }}
							/>
							<Space>
								<Button
									type="primary"
									onClick={() =>
										handleSearch(selectedKeys, confirm, "serialCode")
									}
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
				/>
				<Table.Column
					showSorterTooltip={false}
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("name", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Input
								placeholder={`Buscar nome`}
								value={selectedKeys[0]?.toString()}
								onChange={(e) =>
									setSelectedKeys(e.target.value ? [e.target.value] : [])
								}
								onPressEnter={() => handleSearch(selectedKeys, confirm, "name")}
								style={{ width: 188, marginBottom: 8, display: "block" }}
							/>
							<Space>
								<Button
									type="primary"
									onClick={() => handleSearch(selectedKeys, confirm, "name")}
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
					dataIndex="name"
					title="Nome"
				/>

				<Table.Column
					showSorterTooltip={false}
					dataIndex={["customer", "name"]}
					title="Cliente"
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("customer", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Input
								placeholder={`Buscar cliente`}
								value={selectedKeys[0]?.toString()}
								onChange={(e) =>
									setSelectedKeys(e.target.value ? [e.target.value] : [])
								}
								onPressEnter={() => handleSearch(selectedKeys, confirm, "customer")}
								style={{ width: 188, marginBottom: 8, display: "block" }}
							/>
							<Space>
								<Button
									type="primary"
									onClick={() => handleSearch(selectedKeys, confirm, "customer")}
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
					render={formatOptional}
				/>

				<Table.Column
					showSorterTooltip={false}
					dataIndex={["laundry", "name"]}
					title="Franquia"
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("laundry", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Input
								placeholder={`Buscar franquia`}
								value={selectedKeys[0]?.toString()}
								onChange={(e) =>
									setSelectedKeys(e.target.value ? [e.target.value] : [])
								}
								onPressEnter={() => handleSearch(selectedKeys, confirm, "laundry")}
								style={{ width: 188, marginBottom: 8, display: "block" }}
							/>
							<Space>
								<Button
									type="primary"
									onClick={() => handleSearch(selectedKeys, confirm, "laundry")}
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
					render={formatOptional}
				/>

				<Table.Column
					showSorterTooltip={false}
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("laundryUnit", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Input
								placeholder={`Buscar unidade`}
								value={selectedKeys[0]?.toString()}
								onChange={(e) =>
									setSelectedKeys(e.target.value ? [e.target.value] : [])
								}
								onPressEnter={() =>
									handleSearch(selectedKeys, confirm, "laundryUnit")
								}
								style={{ width: 188, marginBottom: 8, display: "block" }}
							/>
							<Space>
								<Button
									type="primary"
									onClick={() =>
										handleSearch(selectedKeys, confirm, "laundryUnit")
									}
									icon={<SearchOutlined />}
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
					title="Unidade"
					render={renderLaundryUnit}
				/>

				<Table.Column
					dataIndex="status"
					title="Status"
					showSorterTooltip={false}
					sorter={true}
					onHeaderCell={() => ({
						onClick: () => handleSort("status", sortOrder?.order || "ascend"),
					})}
					filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
						<div style={{ padding: 8 }}>
							<Select
								placeholder="Selecionar status"
								style={{ width: 350, marginBottom: 8, display: "block" }}
								value={selectedKeys[0]}
								onChange={(value) => {
									setSelectedKeys(value ? [value] : []);
									handleSearchEnum(value, confirm, "status");
								}}
							>
								<Option value="Idle">Sem comunicação</Option>
								<Option value="Available">Pronto para associar</Option>
								<Option value="NotConfigured">Pronto para configurar</Option>
								<Option value="Operational">Em funcionamento</Option>
								<Option value="Error">Erro</Option>
							</Select>
							<Space>
								<Button
									onClick={() => handleReset(clearFilters)}
									size="small"
									style={{ width: 90 }}
								>
									Limpar
								</Button>
							</Space>
						</div>
					)}
					filterIcon={() => {
						return (
							<SearchOutlined
								style={{ color: searchText ? "#1890ff" : "#00000073" }}
							/>
						);
					}}
					render={(status: DeviceStatus) =>
						tags[status] ?? (
							<Tag color="error" icon={<CloseCircleOutlined />}>
								{status}
							</Tag>
						)
					}
				/>

				<Table.Column<Device>
					key="actions"
					title="Ações"
					render={(_, device) => {
						const deletable = props.isDeviceDeletable(device);

						return (
							<Row justify="center">
								<Space>
									<Button
										ghost
										icon={<BarsOutlined />}
										onClick={() =>
											props.onLogDevice(
												device.id,
												device.serialCode,
												props.onOpen
											)
										}
									/>
									<Button
										ghost
										icon={<EditOutlined />}
										onClick={() => props.onEditDevice(device)}
									/>

									<Button
										title="Download do arquivo de configuracão"
										ghost
										icon={<DownloadOutlined />}
										onClick={() =>
											onDownloadFileConfig(
												deviceDataService.download,
												device.serialCode
											)
										}
									/>

									{!allowCreate && (
										<Popconfirm
											placement="topRight"
											disabled={!deletable}
											title="Tem certeza que deseja mover esse equipamento para trial?"
											onConfirm={() => props.onMoveToTrialDevice(device)}
										>
											<Button
												ghost
												title="Alterar para Trial?"
												disabled={!deletable}
												icon={<SwitcherOutlined />}
											/>
										</Popconfirm>
									)}
									<Button
										title="Adicionar configuração do dispositivo"
										ghost
										disabled={!deletable}
										onClick={() => props.onDeviceData(device)}
										icon={<SettingOutlined />}
									/>

									{!allowCreate && (
										<Popconfirm
											placement="topRight"
											disabled={!deletable}
											title="Tem certeza que deseja remover esse equipamento? Essa ação é irreversível."
											onConfirm={() => props.onDeleteDevice(device)}
										>
											<Button
												ghost
												danger
												disabled={!deletable}
												icon={<DeleteOutlined />}
											/>
										</Popconfirm>
									)}
								</Space>
							</Row>
						);
					}}
				/>
			</Table>
			<Pagination
				style={{ marginTop: "16px", textAlign: "center" }}
				total={total}
				pageSize={5}
				onChange={handlePageChange}
			/>
		</>
	);
};
