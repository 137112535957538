import {
	Button,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Typography,
	Checkbox,
	Tooltip,
	Col,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DeviceDataFormViewProps } from "./types";
import { HourNumberInput } from "../InputHour";
import { hourPattern } from "@/models/rules";
const { Title } = Typography;
const { Option } = Select;

const ssidRegex = /^[a-zA-Z0-9\s-]*$/;

const wifiPassRegex = /^.{8,63}$/;

type ComponentType = React.FC<DeviceDataFormViewProps>;
export const DeviceDataFormView: ComponentType = (props) => {
	const validateTime = (_, value) => {
		const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
		if (!value || timeFormat.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(
			new Error("Por favor, insira um horário válido no formato HH:MM (00:00 - 23:59)")
		);
	};

	return (
		<Form
			layout="vertical"
			form={props.form}
			onFinish={props.onSubmit}
			initialValues={{
				...props.initialValue,
				...(props.initialValue?.pumps || []),
				pumps: Array(Math.max(4, props.initialValue?.pumps?.length || 0))
					.fill({})
					.map((_, index) => {
						return (
							props.initialValue?.pumps?.find((pump) => pump.index === index) || {}
						);
					}),
			}}
		>
			<Form.Item name="deviceId" noStyle>
				<Input type="hidden" />
			</Form.Item>

			<Form.Item label="Versão do Firmware" name="firmwareVersion">
				<Input disabled placeholder="Digite a versão do firmware" />
			</Form.Item>

			<Form.Item label="Idioma" name="language" rules={[{ message: "Selecione um idioma" }]}>
				<Select placeholder="Selecione o idioma">
					<Option value="0">EN</Option>
					<Option value="1">PT</Option>
					<Option value="2">ES</Option>
					<Option value="3">IT</Option>
				</Select>
			</Form.Item>

			<Form.Item
				label="Senha Física"
				name="phisicalPass"
				rules={[
					{ message: "Digite a senha física" },
					{
						pattern: /^[1-4]{4,6}$/,
						message: "A senha deve ter entre 4 a 6 dígitos de 1 a 4",
					},
				]}
			>
				<Input.Password placeholder="Digite a senha física" />
			</Form.Item>

			<Form.Item
				label="Nome WiFi do Equipamento"
				name="ssidAp"
				rules={[
					{ message: "Digite o SSID do AP" },
					{ pattern: ssidRegex, message: "SSID inválido" },
				]}
			>
				<Input placeholder="Digite o SSID do AP" />
			</Form.Item>

			<Form.Item
				label="Senha WiFi do Equipamento"
				name="passAp"
				rules={[
					{ message: "Digite a Senha WiFi do Equipamento" },
					{ pattern: wifiPassRegex, message: "A senha deve ter entre 8 e 63 caracteres" },
				]}
			>
				<Input.Password placeholder="Digite a Senha WiFi do Equipamento" />
			</Form.Item>

			<Form.Item
				label="Nome WiFi Local"
				name="ssidStation"
				rules={[
					{ message: "Digite o Nome WiFi Local" },
					{ pattern: ssidRegex, message: "Nome WiFi Local" },
				]}
			>
				<Input placeholder="Digite o Nome WiFi Local" />
			</Form.Item>

			<Form.Item
				label="Senha WiFi Local"
				name="passStation"
				rules={[
					{ message: "Digite a Senha WiFi Local" },
					{ pattern: wifiPassRegex, message: "A senha deve ter entre 8 e 63 caracteres" },
				]}
			>
				<Input.Password placeholder="Digite a Senha WiFi Local" />
			</Form.Item>

			<Form.Item
				label="E-mail"
				name="emailClient"
				rules={[
					{ type: "email", message: "Digite um e-mail válido" },
					{ max: 100, message: "O e-mail pode ter no máximo 100 caracteres" },
				]}
			>
				<Input placeholder="Digite o e-mail" />
			</Form.Item>

			<Title level={5}>Verificar e Atualizar Equipamento</Title>

			<Form.Item
				name="dailyTimeUpdate"
				label="Hora/Dia(s) da Verificação"
				rules={[hourPattern, { validator: validateTime }]}
			>
				<HourNumberInput disabled={props.submitting} />
			</Form.Item>
			<Row gutter={[16, 16]}>
				<Col>
					<Form.Item name="sundayOptionUpdate" valuePropName="checked">
						<Checkbox>Dom</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="mondayOptionUpdate" valuePropName="checked">
						<Checkbox>Seg</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="tuesdayOptionUpdate" valuePropName="checked">
						<Checkbox>Ter</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="wednesdayOptionUpdate" valuePropName="checked">
						<Checkbox>Qua</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="thursdayOptionUpdate" valuePropName="checked">
						<Checkbox>Qui</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="fridayOptionUpdate" valuePropName="checked">
						<Checkbox>Sex</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="saturdayOptionUpdate" valuePropName="checked">
						<Checkbox>Sáb</Checkbox>
					</Form.Item>
				</Col>

				<Col>
					<Form.Item name="updateAlways" valuePropName="checked">
						<Checkbox>
							<span>
								Verificação Recorrente&nbsp;
								<Tooltip title="Marque essa opção para que, sempre que houver novas atualizações disponíveis, o equipamento seja atualizado automaticamente.">
									<InfoCircleOutlined style={{ fontSize: "12px" }} />
								</Tooltip>
							</span>
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>

			<Form.Item
				label="Tempo de Flush (seg.)"
				name="flush"
				rules={[
					{ message: "Digite um valor para Flush" },
					({ getFieldValue }) => ({
						validator(_, value) {
							if (
								!value ||
								(Number.isInteger(Number(value)) && value >= 0 && value <= 3600)
							) {
								return Promise.resolve();
							}
							return Promise.reject(new Error("O valor deve estar entre 0 e 3600"));
						},
					}),
				]}
			>
				<Input type="number" placeholder="Digite o valor para Flush" />
			</Form.Item>

			<Divider />

			<Title level={4}>Bombas</Title>
			<Form.List name="pumps">
				{(fields, { add, remove }) => (
					<>
						<div style={{ overflowX: "auto", whiteSpace: "nowrap", display: "flex" }}>
							{fields.map(({ key, name }) => (
								<div
									key={key}
									style={{
										display: "inline-block",
										width: "300px",
										marginRight: "16px",
										padding: "16px",
										border: "1px solid #d9d9d9",
										borderRadius: "8px",
									}}
								>
									<Title style={{ minWidth: "450px" }} level={5}>
										Bomba {name + 1}
									</Title>

									<Form.Item name={[name, "name"]} label="Nome">
										<Input placeholder="Digite o nome" />
									</Form.Item>

									<Form.Item
										name={[name, "currentCalibration"]}
										label="Calibração (ml/seg)"
										rules={[
											{
												message: "Digite a calibração",
											},
											{
												pattern: /^\d{1,4}(\.\d{1,2})?$/,
												message:
													"A calibração deve estar entre 1.00 e 9999.99",
											},
										]}
									>
										<Input placeholder="Digite a calibração atual" />
									</Form.Item>

									<Form.Item
										name={[name, "primeTime"]}
										label="Tempo de Prime (seg.)"
										rules={[
											{ message: "Digite o tempo de prime" },
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														(Number.isInteger(Number(value)) &&
															value >= 1 &&
															value <= 300)
													) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															"O valor deve estar entre 1 e 300"
														)
													);
												},
											}),
										]}
									>
										<Input
											type="number"
											placeholder="Digite o tempo de prime"
										/>
									</Form.Item>

									<Form.Item
										name={[name, "inputSignalMaxTime"]}
										label="Dosagem (ml)"
										rules={[
											{ message: "Digite a dosagem (ml)" },
											({ getFieldValue }) => ({
												validator: (_, value) => {
													const calibrationValue = getFieldValue([
														"pumps",
														name,
														"currentCalibration",
													]);
													const dosagemValue = Number(value);

													// Validar se calibração e dosagem excedem 300
													if (!calibrationValue || !dosagemValue) {
														return Promise.resolve();
													}

													const calibNum = Number(calibrationValue);

													if (isNaN(calibNum) || isNaN(dosagemValue)) {
														return Promise.reject(
															new Error(
																"A calibração e dosagem devem ser números"
															)
														);
													}

													if (calibNum / dosagemValue > 300) {
														return Promise.reject(
															new Error(
																"Bomba excedeu tempo limite de funcionamento, rever calibração ou dosagem"
															)
														);
													}

													return Promise.resolve();
												},
											}),
										]}
									>
										<Input placeholder="Digite a dosagem (ml)" />
									</Form.Item>
								</div>
							))}
						</div>
					</>
				)}
			</Form.List>

			<Row justify="end">
				<Button
					type="primary"
					htmlType="submit"
					disabled={props.submitting}
					loading={props.submitting}
				>
					Salvar
				</Button>
			</Row>
		</Form>
	);
};
