import { DeviceDataService } from "./types";

export const deviceDataService: DeviceDataService = {
	list: ({ serialCode, name, page, pageSize }) => ({
		method: "GET",
		path: `/v1/device-data?serialCode=${serialCode}&name=${name}&page=${page}&pageSize=${pageSize}`,
	}),
	get: ({ deviceDataId }) => ({
		method: "GET",
		path: `/v1/device-data/${deviceDataId}`,
	}),
	getBySerialCode: ({ serialCode }) => ({
		method: "GET",
		path: `/v1/device-data/serial-code/${serialCode}`,
	}),
	create: (json) => ({
		json,
		method: "POST",
		path: `/v1/device-data`,
	}),
	edit: ({ deviceDataId, ...json }) => ({
		json,
		method: "PUT",
		path: `/v1/device-data/${deviceDataId}`,
	}),
	download: ({ serialCode }) => ({
		method: "GET",
		path: `/v1/device-data/download/${serialCode}`,
	}),

	delete: ({ deviceDataId }) => ({
		method: "DELETE",
		path: `/v1/device-data/${deviceDataId}`,
	}),
};
