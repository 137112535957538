import { DeviceDataForm } from "@/components/DeviceDataForm";
import { Action } from "@/models/actions/Action";
import { ActionControls } from "@/models/actions/ActionControls";
import { DeviceData } from "@/models/DeviceData";
import { notification } from "antd";

export class DeviceDataAction implements Action {
	constructor(private readonly deviceId: string, private readonly deviceData?: DeviceData) {
	}

	getOptions() {
		return {
			width: "60vw",
			title: this.deviceData ? "Editar configuração" : "Adicionar configuração",
		};
	}

	render(controls: ActionControls) {
		return (
			<DeviceDataForm
				deviceData={this.deviceData}
				deviceId={this.deviceId}
				onSubmitting={() => controls.setCloseable(false)}
				onSubmitted={(deviceData) => this.onSuccess(deviceData, controls)}
				onSubmissionFailed={(error) => this.onFailure(error, controls)}
			/>
		);
	}

	private onSuccess(_device: DeviceData, controls: ActionControls) {
		controls.setCloseable(true);
		controls.close();

		notification.success({ message: "Em até 10 minutos o seu equipamento será atualizado!" });
	}

	private async onFailure(error: any, controls: ActionControls) {
		if (error?.response?.status === 406) {
			const errorMessage = await error?.response.json();
			controls.setCloseable(true);
			notification.error({ message: errorMessage.message });
			return;
		}
		controls.setCloseable(true);

		notification.error({ message: "Erro ao salvar configuração" });
	}
}
